@font-face {
    font-family: 'poppins';
    src: url('../../Fonts/Poppins/Poppins-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'poppins';
    src: url('../../Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'poppins';
    src: url('../../Fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: light;
    font-style: normal;
  }
  @font-face {
    font-family: 'playwrite';
    src: url('../../Fonts/Playwrite/PlaywriteBEWAL-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'bungeeshade';
    src: url('../../Fonts/Bungee_Shade/BungeeShade-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'squarepeg';
    src: url('../../Fonts/Square_Peg/SquarePeg-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'nordic';
    src: url('../../Fonts/Nordic/Nordic\ Alternative\ Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'brittany';
    src: url('../../Fonts/brittany-signature/BrittanySignature.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'newpose';
    src: url('../../Fonts/NewPose.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }